import React from "react"
import { SubpageTitle } from "components/Typography"
import { TitleContainer } from "./styles"

const CaseTitle = ({title}) => {
  return (
    <TitleContainer>
      <SubpageTitle>{title}</SubpageTitle>
    </TitleContainer>
  )
}

export { CaseTitle }
