import React from 'react';
import {OverflowWrapper, PageWrapper} from '../../shared/Styles/Wrappers';
import {CaseTitle} from './CaseTitle';
import {CaseContainer} from './CaseContainer';
import {BrushedTextContainer} from '../../components/BrushedTextContainer';
import SEO from '../../components/seo';

const CaseStudy = ({data}) => {
  return (
    <>
      <SEO title={data.seo.title}/>
      <OverflowWrapper>
        <PageWrapper>
          <CaseTitle title={data.title}/>
          <BrushedTextContainer x={[
            -30,
            5,
          ]} posY="-210px">
            {data.parallaxText}
          </BrushedTextContainer>
          <CaseContainer data={data.cases}/>
        </PageWrapper>
      </OverflowWrapper>
    </>
  );
};

export default CaseStudy;
