import styled, { css } from "styled-components"

export const CardCommon = css`
  height: 738px;
  width: 50%;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopSmall}) {
    height: 600px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: unset;
    flex-grow: 1;
    height: 400px;
    margin: 0 -30px;
    padding: 20px;
  }
`
export const Card = styled.div`
  display: flex;
  flex-direction: ${props => (props.imageLeft ? "row" : "row-reverse")};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`

export const ImageSide = styled.div`
  ${CardCommon}
`

export const ContentSide = styled.div`
  background-color: ${({ theme, active }) =>
    active ? theme.color.green : "#F8F8F8"};
  transition: 0.5s background-color ease-in-out;
  position: relative;
  ${CardCommon}

  &::before {
    content: " ";
    position: absolute;
    background-color: #f8f8f8;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 100%;
    right: ${props => (props.imageLeft ? "-100%" : "unset")};
    left: ${props => (props.imageLeft ? "unset" : "-100%")};
  }

  &::after {
    content: " ";
    position: absolute;
    background-color: ${({ theme }) => theme.color.lightGreen};
    z-index: -1;
    top: 0;
    width: 100%;
    height: 100%;
    left: ${props =>
      props.imageLeft
        ? props.active
          ? "100%"
          : "200%"
        : props.active
        ? "-100%"
        : "-200%"};
    transition: 0.5s left ease-in-out;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      z-index: 2;
      left: ${props =>
        props.imageLeft
          ? props.active
            ? "92%"
            : "200%"
          : props.active
          ? "-92%"
          : "-200%"};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    width: unset;
    flex-grow: 1;
    height: unset;
    min-height: 480px;
    margin: 0 -30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
`
