import {useEnCaseStudyData} from '../../shared/Hooks/CaseStudy/useEnCaseStudyData';
import Image from 'gatsby-image';
import React from 'react';
import {useEnBackgroundImage} from '../../shared/Hooks/Background/useEnBackgroundImage';

export const useData = () => {
  const cases = useEnCaseStudyData();
  const backgroundImgs = useEnBackgroundImage();

  return {
    seo: {
      title: 'Case study',
    },
    parallaxText: (
      <Image fixed={backgroundImgs.caseStudy.childImageSharp.fixed}/>
    ),
    title: 'Case study',
    cases,
  };
};
