import React from 'react';
import {CaseCard} from 'sections/CaseStudy/CaseCard';

export const CaseContainer = ({data}) => {
  return (
    <>
      {data.map((studyCase, index) => (
        <CaseCard
          index={index}
          key={studyCase.name}
          imageLeft={!(index % 2)}
          caseStudy={studyCase}
        />
      ))}
    </>
  );
};
