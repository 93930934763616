import React, {useState} from 'react';
import {CaseCard as Card} from 'components/CaseCard';
import Img from 'gatsby-image';
import {
  ImageWrapper,
  CustomImage,
  ContentWrapper,
  SeeMoreButton,
  NumberHeader,
  CaseTitle,
  TextParagraph,
} from './styles';

export const CaseCard = ({
  caseStudy,
  imageLeft,
}) => {
  const [mouseOver, setMouseOver] = useState(false);

  return (
    <Card
      active={mouseOver}
      imageLeft={imageLeft}
      image={
        <CustomImage>
          <ImageWrapper>
            <Img imgStyle={{objectFit: 'contain'}} fluid={caseStudy.image}/>
          </ImageWrapper>
        </CustomImage>
      }
    >
      <ContentWrapper
        left={imageLeft}
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <NumberHeader color={'inherit'}>{caseStudy.number}</NumberHeader>
        <CaseTitle color={'inherit'}>{caseStudy.name}</CaseTitle>
        <TextParagraph color={'inherit'}>{caseStudy.description}</TextParagraph>
        <SeeMoreButton href={caseStudy.link}>{caseStudy.btnText}</SeeMoreButton>
      </ContentWrapper>
    </Card>
  );
};
