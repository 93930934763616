import React from 'react';
import {useData} from '../../../data/en/case-study';
import CaseStudy from '../../../sections/CaseStudy';

const Index = () => {
  const data = useData();

  return (
    <CaseStudy data={data}/>
  );
};
export default Index;
