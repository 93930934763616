import React from "react"
import { Card, ImageSide, ContentSide } from "./styles"

export const CaseCard = ({ children, image, imageLeft, active, index }) => {
  return (
    <Card imageLeft={imageLeft}>
      <ImageSide imageLeft={imageLeft}>{image}</ImageSide>
      <ContentSide active={active} imageLeft={imageLeft}>
        {children}
      </ContentSide>
    </Card>
  )
}
