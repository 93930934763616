import styled from "styled-components"
import { NormalHeader, Paragraph } from "components/Typography"

export const CustomImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopSmall}) {
    padding: 60px 30px;
  }
`
export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopSmall}) {
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
  }
`
export const TextParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.color.grayText};
  transition: 0.5s color ease-in-out;
`

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 180px 140px;
  cursor: pointer;
  position: realtive;
  transition: 0.5s color ease-in-out;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopSmall}) {
    padding: 90px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    padding: 79px 50px;
  }

  &:hover {
    color: white;
    &::after {
      background: ${({ theme }) => theme.color.lightGreen};
    }
    ${TextParagraph} {
      color: ${({ theme }) => theme.color.white};
    }
  }

  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: ${({ left }) => (left ? "-75px" : "unset")};
    right: ${({ left }) => (left ? "unset" : "-75px")};
    width: 150px;
    height: 2px;
    transition: 0.5s background-color ease-in-out;
    background: ${({ theme }) => theme.color.gray};

    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.laptopSmall}) {
      width: 90px;
      left: ${({ left }) => (left ? "-45px" : "unset")};
      right: ${({ left }) => (left ? "unset" : "-45px")};
    }

    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tabletSmall}) {
      width: 2px;
      height: 80px;
      top: -40px;
      left: 50%;
    }
  }
`
export const SeeMoreButton = styled.a`
  text-decoration: none;
  display: block;
  color: ${({ theme }) => theme.color.white};
  padding: 14px 32px;
  max-width: 170px;
  text-align: center;
  font-weight: bold;
  background: ${({ theme }) => theme.color.green};
  border-radius: 2px;
  border: solid 2px white;
  font-size: 14px;
  margin-top: 30px;
`
export const NumberHeader = styled.div`
  font-size: 60px;
  margin: 30px 0;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopSmall}) {
    font-size: 24px;
    margin: 15px 0;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    font-size: 40px;
  }
`
export const CaseTitle = styled(NormalHeader)`
  margin: 20px 0;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopSmall}) {
    font-size: 24px;
  }
`
