import React from 'react';
import {useCaseStudyPhotos} from './useCaseStudyPhotos';

export const useEnCaseStudyData = () => {
  const photos = useCaseStudyPhotos();
  return [
    {
      name: 'Cersanit and Opoczno - a shop-in-shop project',
      number: '01',
      link: '/en/case-study/cersanit',
      description: (
        <>
          This project assumed preparation of a set of POS materials for the products of...
        </>
      ),
      image: photos.cersanit.childImageSharp.fluid,
      btnText: 'See more',
    },
    {
      name: 'Futuro and Viscoplast – mini-campaigns',
      number: '02',
      link: '/en/case-study/futuro-viscoplast',
      description: (
        <>
          VOX shops is a network of interior
          design stores offering customers...
        </>
      ),
      image: photos.futuro.childImageSharp.fluid,
      btnText: 'See more',
    },
    {
      name: 'Shell deli2go – Snack bars at petrol stations',
      number: '03',
      link: '/en/case-study/shell-deli2go',
      description: (
        <>
          The main assumption of the deli2go project was to create a cosy area of shop-in-shop snack bars at...
        </>
      ),
      image: photos.shell.childImageSharp.fluid,
      btnText: 'See more',
    },
    {
      name: 'VOX – a modular display system',
      number: '04',
      link: '/en/case-study/vox',
      description: (
        <>
          VOX shops is a network of interior
          design stores offering customers the ability to arrange room...
        </>
      ),
      image: photos.vox.childImageSharp.fluid,
      btnText: 'See more',
    },
  ];
};
