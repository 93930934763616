import { graphql, useStaticQuery } from "gatsby"

export const useCaseStudyPhotos = () => {
  const caseStudyPhotos = useStaticQuery(graphql`
    query {
      cersanit: file(relativePath: { eq: "img/CaseStudy/cersanit.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      futuro: file(relativePath: { eq: "img/CaseStudy/futuro.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      shell: file(relativePath: { eq: "img/CaseStudy/plyn.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      vox: file(relativePath: { eq: "img/CaseStudy/vox.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return caseStudyPhotos
}
